import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";

import { Container, A, P } from "@util/standard";
import { BlocksContent } from ".";
import { Query } from "@graphql-types";
import { colors, colorsRGB, TABLET_BREAKPOINT } from "@util/constants";
import { activeCampaignAddContact, processEmail } from "@util/helper";
import { toast } from "react-toastify";

const TextContainer = styled(Container)`
  h1,
  h2,
  h3,
  h4,
  p {
    color: ${colors.darkerGreen};
  }

  h2 {
    font-size: 65px;
    line-height: 71px;
  }
  p {
    margin: 20px 0 0 0;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    h2 {
      font-size: 36px;
      line-height: 36px;
    }
  }
`;

const StyledInput = styled.input`
  background: transparent;
  border: none;
  width: 100%;
  border-bottom: 1px solid ${colorsRGB.darkerGreen(0.4)};
  padding-bottom: 12px;
  color: ${colors.darkerGreen};
  ::placeholder {
    color: ${colorsRGB.darkerGreen(0.4)};
  }
  :focus-visible {
    outline: none;
  }
`;

const ThankYouContainer = styled(Container)`
  flex-direction: column;
  h2,
  h3,
  h4,
  p {
    color: ${colors.darkerGreen};
  }

  margin: 0;
`;

function FormBlock() {
  const { sanityGlobalFormBlock }: Query = useStaticQuery(graphql`
    {
      sanityGlobalFormBlock {
        id
        formText {
          _rawColumnContent
          _key
          _type
        }
        thankyouText {
          _rawColumnContent
        }
      }
    }
  `);

  if (sanityGlobalFormBlock == null) {
    return null;
  }

  const handleFormSubmit = e => {
    if (emailInputValue === "" || !emailInputValue.includes("@")) {
      toast.error("Please enter a correct email");
      return;
    }
    const formattedEmail = {
      from: "info@langbein.com",
      to: process.env.GATSBY_CONTACT_RECIPIENT_EMAIL,
      subject: `Langbein.com newsletter signup`,
      html: `<div>
     <p>Email: ${emailInputValue}</p>
      </div>
        `,
    };
    processEmail(formattedEmail)
      .then(result => {
        setFormSubmitted(true);
      })
      .catch(error => {
        toast.error("Something went wrong");
      });
  };

  const [emailInputValue, setEmailInputValue] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
    <Container backgroundRGBColor={colorsRGB.darkerGreen(0.05)} width="100%">
      <Container width="42%" margin="100px auto" tabletMargin="90px auto" tabletWidth="80%" flexDirection="column">
        <TextContainer>
          {sanityGlobalFormBlock.formText && (
            <BlocksContent blocks={sanityGlobalFormBlock.formText._rawColumnContent} />
          )}
        </TextContainer>
        {!formSubmitted && (
          <Container flexDirection="column" margin="30px 0 0 0">
            <StyledInput placeholder="Email address" onChange={e => setEmailInputValue(e.target.value)} />
            <Container justifyContent="flex-end" margin="20px 0 0 0">
              <A color="darkerGreen" underlined onClick={e => handleFormSubmit(e)}>
                Sign Up
              </A>
            </Container>
          </Container>
        )}
        {formSubmitted && sanityGlobalFormBlock.thankyouText && (
          <ThankYouContainer>
            <BlocksContent blocks={sanityGlobalFormBlock.thankyouText._rawColumnContent} />
          </ThankYouContainer>
        )}
      </Container>
    </Container>
  );
}

export default FormBlock;
