import React, { useEffect } from "react";
import { PageProps, graphql } from "gatsby";
import { Query } from "@graphql-types";
import Hero from "@components/global/hero";
import LearnCategoryPreview from "@components/global/learnCategoryPreview";
import { isSanityCtaBanner, isSanityLearnCategoryPreview } from "@util/types";
import CtaBanner from "@components/global/ctaBanner";
import { Container } from "@util/standard";
import blogsQuery from "@src/query/blogsQuery";
import BlogsGrid from "@components/blog/blogsGrid";
import FormBlock from "@components/global/formBlock";
import { InstantSearch, connectHits, Configure } from "react-instantsearch-dom";
import { useStore } from "@state/store";
import SEO from "@shared/seo";
import Layout from "@shared/layout";
import { searchClient } from "@util/helper";

interface Props extends PageProps {
  data: Query;
}

function LearnCategoryTemplate({ data }: Props) {
  const { hero, pageContent, name, heroFilters, seo, slug } = data.allSanityLearnCategories.nodes[0];

  const Hits = ({ hits }: any) => {
    return (
      <Container flexDirection="column" width="100%" margin="80px auto">
        {hits && <BlogsGrid learnCategory={name as string} blogs={hits} maxIndex={5} hideLoadMoreButton />}
        <Container width="88%" tabletWidth="100%" margin="60px auto">
          <FormBlock />
        </Container>
        {hits && hits.length > 6 && <BlogsGrid learnCategory={name as string} blogs={hits} startIndex={6} />}
      </Container>
    );
  };

  const DefaultHits = () => {
    const hits = blogsQuery();

    return (
      <Container flexDirection="column" width="100%" margin="80px auto">
        {hits && <BlogsGrid learnCategory={name as string} blogs={hits} maxIndex={5} hideLoadMoreButton />}
        <Container width="88%" tabletWidth="100%" margin="60px auto">
          <FormBlock />
        </Container>
        {hits && hits.length > 6 && <BlogsGrid learnCategory={name as string} blogs={hits} startIndex={6} />}
      </Container>
    );
  };

  const CustomHits = connectHits(Hits);

  const { searchParams } = useStore();

  useEffect(() => {
    useStore.setState({ searchFilters: [] });
  }, []);

  return (
    <div>
      {seo && <SEO seoData={seo} slug={`learn/${slug.current}`} />}
      <Layout>
        <InstantSearch indexName="Blogs" searchClient={searchClient}>
          <Configure hitsPerPage={1000} />

          {hero && <Hero noMaxHeight data={hero} isLearnPage filterLists={heroFilters} />}

          {searchParams.hasSearched ? <CustomHits /> : <DefaultHits />}
        </InstantSearch>
        <div>
          {pageContent &&
            pageContent.map(data => {
              if (data == null) {
                return null;
              }

              if (isSanityLearnCategoryPreview(data)) {
                return <LearnCategoryPreview data={data} key={data._key} />;
              }

              if (isSanityCtaBanner(data)) {
                return <CtaBanner data={data} key={data._key} />;
              }
              return;
            })}
        </div>
      </Layout>
    </div>
  );
}

export default LearnCategoryTemplate;

export const query = graphql`
  query learnCategoriesQuery($slug: String) {
    allSanityLearnCategories(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        ...sanityLearnCategories
      }
    }
  }
`;
