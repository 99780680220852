import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const blogsQuery = (category?: string) => {
  const { allSanityBlog }: Query = useStaticQuery(graphql`
    query blogQuery {
      allSanityBlog {
        nodes {
          ...sanityBlog
        }
      }
    }
  `);

  if (category) {
    const filteredBlogs = allSanityBlog.nodes.filter(blog => {
      return blog.category?.name === category;
    });
    return filteredBlogs;
  }

  return allSanityBlog.nodes;
};

export default blogsQuery;
