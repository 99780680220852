import { Button } from "@components/global";
import { SanityBlog } from "@graphql-types";
import { Container, GridContainer } from "@util/standard";
import React, { useState } from "react";
import BlogGridCard from "./blogGridCard";

interface Props {
  blogs: SanityBlog[];
  maxIndex?: number;
  startIndex?: number;
  learnCategory?: string;
  hideLoadMoreButton?: boolean;
}

function BlogsGrid({ blogs, maxIndex, startIndex, learnCategory, hideLoadMoreButton }: Props) {
  const [numbersToShow, setNumbersToShow] = useState(15);

  if (blogs == null) {
    return null;
  }

  const filteredBlogs = blogs.filter(blog => {
    return blog.category?.name === learnCategory;
  });

  return (
    <>
      <GridContainer repeat={3} width="88%" margin="0 auto" columnGap="1%" rowGap={60}>
        {filteredBlogs.map((blog, index) => {
          if (blog == null) {
            return null;
          }

          if (startIndex && index < startIndex) {
            return null;
          }
          if (maxIndex && index > maxIndex) {
            return null;
          }

          if (!hideLoadMoreButton && index >= numbersToShow) {
            return null;
          }

          return <BlogGridCard data={blog} blogId={blog.objectID as string} key={blog.objectID} />;
        })}
      </GridContainer>
      {!hideLoadMoreButton && filteredBlogs && filteredBlogs.length > 0 && numbersToShow <= filteredBlogs?.length - 1 && (
        <Container margin="60px auto 0 auto">
          <Button margin="auto" theme="block" text="Load More" onClick={() => setNumbersToShow(numbersToShow + 12)} />
        </Container>
      )}
    </>
  );
}

export default BlogsGrid;
